.footer {
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
  color: white;
}

.footer-logo-text-first {
  margin: 1rem;
  font-weight: 600;
}
.footer-elements {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
.footer-left {
  flex: 1;
  text-align: center;
}

.footer-follow-us {
  color: var(--primary-color);
  font-weight: 600;
}

.footer-social-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.footer-social-logo {
  color: white;
}

.footer-middle {
  flex: 1;
  color: white;
  display: none;
  text-align: center;
}

.footer-logo-text {
  color: white;
  text-align: center;
  font-weight: 600;
}

.footer-right {
  color: white;
  display: flex;
  flex-direction: column;
}

.footer-contact-us {
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
}

.footer-contact-line {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: center;
}

.footer-contact {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.footer-copyright {
  color: lightgray;
  text-align: center;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1000px) {
  .footer-elements {
    flex-direction: row;
  }
  .footer-left {
    text-align: left;
  }
  .footer-middle {
    display: flex;
    margin-left: 7rem;
  }
  .footer-logo-text-first {
    display: none;
  }

  .footer-social-logos {
    justify-content: flex-start;
  }

  .footer-right {
    flex: -1;
  }

  .footer-contact-us {
    text-align: left;
  }

  .footer-contact {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
  }
}
