@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,800;0,900;1,400&display=swap");

html {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #ef2a47;
  --secondary-color: #f7b024;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", "Roboto", "sans-serif";
  text-decoration: none;
  list-style: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  margin-block: 20px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  margin-block: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
