.broadcasting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}

.broadcast-introduction {
  margin: 70px auto;
}

.broadcast-introduction h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  line-height: 26px;
  color: white;
}

.broadcasting-conditions-container .title {
  position: relative;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.broadcasting-conditions-container .title p {
  font-size: 14px;
  color: white;
}

.broadcast-double-overlay {
  width: 30px;
  height: 30px;
  margin-right: 18px;
  margin-top: -8px;
}

.broadcast-double-overlay .double-arrow-1 {
  position: absolute;
  top: 30px;
  left: -10px;
  fill: var(--secondary-color) !important;
  font-size: 35px;
}

.broadcast-double-overlay .double-arrow-2 {
  position: absolute;
  top: 30px;
  left: -20px;
  fill: var(--secondary-color) !important;
  font-size: 35px;
}

.title h2 {
  color: var(--primary-color);
}

.broadcast-extra-information {
  margin-top: 40px;
}

.broadcast-extra-information p {
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary-color);
}
