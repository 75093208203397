a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
}

.navbar p {
  color: white;
}

.navbar p:hover {
  color: var(--primary-color);
}

.logo-part {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.logo-text {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.logo-text-second {
  margin-left: 1rem;
}

.links {
  display: none;
}

.nav-text {
  width: 25%;
  text-align: center;
  border-right: solid 3px var(--primary-color);
  min-height: 2.5rem;
}

.nav-text:nth-child(3) {
  border: none;
  margin-left: 1rem;
}

.nav-apply-now-button {
  background-color: var(--primary-color);
  color: #ffffff;
  border: 2px solid var(--primary-color);
  border-radius: 0.9rem;
  width: 7rem;
  height: 2.5rem;
  margin-left: 2rem;
  font-weight: 800;
  font-size: 0.9rem;
}
.nav-apply-now-button:hover {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: #000000;
  cursor: pointer;
}

.hamburger-apply-now-button {
  background-color: #e3e3e3;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 0.9rem;
  width: 9rem;
  height: 2.5rem;
  font-weight: 800;
  font-size: 0.9rem;
  margin-top: 1rem;
  cursor: pointer;
}
.hamburger {
  color: var(--primary-color);
  font-size: 4rem;
}

.nav-menu {
  background-color: var(--primary-color);
  width: 50%;
  height: 60%;
  max-width: 12rem;
  max-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10;
  right: -500%;
  transition: 200ms;
  z-index: 2;
  padding: 1rem;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-menu-items {
  display: flex;
  flex-direction: column;
}

.menu-bars-close {
  align-self: flex-end;
  transition: 200ms;
  font-size: 2rem;
}

.links-hamburger {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
}

.nav-text-hamburger {
  text-align: center;
  border-bottom: solid 1px white;
  padding-bottom: 1rem;
}

.nav-text-hamburger:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 1000px) {
  .hamburger {
    display: none;
  }

  .menu-bars-close {
    display: none;
  }

  .links {
    display: flex;
  }
}
