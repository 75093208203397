.main {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.page-introduction {
  margin: 70px auto;
}

.page-introduction h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  line-height: 26px;
  color: white;
}

p {
  font-size: 14px;
}

.conditions-container .title {
  display: flex;
}

.description p {
  text-align: left;
}

.double-overlay {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 18px;
  margin-top: -8px;
}

.double-overlay .double-arrow-1 {
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--primary-color);
  font-size: 45px;
}

.double-overlay .double-arrow-2 {
  position: absolute;
  top: 0;
  left: 10px;
  fill: var(--primary-color) !important;
  font-size: 45px;
}

.title h2 {
  color: var(--primary-color);
}

.description {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.description-list {
  display: flex;
  margin: 20px 0 10px 18px;
}

.single-arrow {
  margin: -3px 3px 0 0;
  fill: var(--secondary-color) !important;
  font-size: 35px;
}

.awards-container .title {
  display: flex;
}

.awards-container h5 {
  font-weight: 600;
  font-size: 16px;
  margin-left: -5px;
  color: var(--secondary-color);
}

.paragraph {
  margin: -5px 0 7px 45px;
  list-style: circle;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paragraph p {
  text-align: left;
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 7px;
  flex-wrap: wrap;
}

.btn-container img {
  width: 200px
}

.nominate-btn {
  padding: 8px 16px;
  font-weight: 600;
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  margin: 30px auto 120px auto;
  cursor: pointer;
}

.nominate-btn a {
  color: white;
}

.nominate-btn:hover {
  background-color: #9e0c22;
}

.submitting-container .title {
  display: flex;
}

.submitting-container h5 {
  font-weight: 600;
  font-size: 16px;
  margin-left: -5px;
  color: var(--secondary-color);
}

.submitting-container-info {
  width: 80%;
  margin-left: 45px;
  margin-top: 10px;
  color: white;
}

.link-to-jury {
  font-size: 14px;
  color: var(--secondary-color);
  text-decoration: underline;
}

.link-container {
  display: flex;
  width: fit-content;
  margin: 20px;
}

.link-container:hover .double-overlay .submitting-single-arrow-1 {
  fill: rgb(71, 119, 250) !important;
  transform: translate(10px, 5px);
  transition: transform 0.5s;
}
.link-container:hover .double-overlay .submitting-single-arrow-2 {
  fill: rgb(71, 119, 250) !important;
  transition: transform 0.5s;
}
.link-container:hover .link-to-jury {
  color: rgb(71, 119, 250) !important;
}

.double-overlay .submitting-single-arrow-1 {
  position: absolute;
  transform: translate(2px, 5px);
  fill: var(--secondary-color) !important;
  font-size: 25px;
}

.double-overlay .submitting-single-arrow-2 {
  position: absolute;
  transform: translate(10px, 5px);
  fill: var(--secondary-color) !important;
  font-size: 25px;
}

.submitting-date {
  display: flex;
  justify-content: center;
}

.submitting-date p {
  color: var(--primary-color) !important;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}
